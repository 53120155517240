<script setup lang="ts">
const { data: userData } = useAuth();
const { t } = useI18n();
const { width } = useWindowSize();
const mobileSize = computed(() => width.value < 600);
const showRegButtons = ref(false);
const localePath = useLocalePath();
const isMounted = useMounted();
onMounted(async () => {
  if (!userData.value) {
    await nextTick();
    showRegButtons.value = true;
  }
});
const carouselRef = ref<any | null>(null);
const { lengthX, direction } = useSwipe(carouselRef, {
  passive: true,
  onSwipeEnd() {
    if (Math.abs(lengthX.value) < 25) {
      return;
    }
    (carouselRef.value as any)?.[
      direction.value === "left" ? "next" : "prev"
    ]();
  },
});
const steps = ref([0, 1, 2]);
const interval = ref(8000);
const activeStep = computed({
  get: () => carouselRef.value?.activeIndex || 0,
  set: (val) => {
    carouselRef.value?.setActiveItem(val);
  },
});
</script>

<template>
  <div class="carousel-cont">
    <ClientOnly>
      <template #fallback>
        <el-skeleton style="width: 100%" animated>
          <template #template>
            <el-skeleton-item
              variant="image"
              style="width: 100%"
              :style="`height: ${isMounted && mobileSize ? '230px' : '320px'}`"
            />
          </template>
        </el-skeleton>
      </template>
      <div class="steps-cont">
        <div
          class="step-item"
          v-for="step in steps"
          :key="step"
          :data-timer="interval"
          :class="{
            'is-active': activeStep === step,
            'is-done': activeStep > step,
          }"
          @click="activeStep = step"
        ></div>
      </div>
      <el-carousel
        class="border-radius-LG"
        :height="`${isMounted && mobileSize ? '230px' : '320px'}`"
        :initial-index="0"
        trigger="click"
        arrow="never"
        :interval="interval"
        :autoplay="true"
        ref="carouselRef"
        indicator-position="none"
      >
        <el-carousel-item>
          <ExchangePromo />
        </el-carousel-item>
        <el-carousel-item class="walpaper-item">
          <BlogWidget :limit="1" wallpaper />
        </el-carousel-item>
        <el-carousel-item class="bg-surface-secondary">
          <div class="p-4 flex flex-col box-border h-full">
            <h1 class="display-size-SM mb-0">
              {{ t("p-index-welcome-title") }}
            </h1>
            <p class="par-size-SM mb-4" v-if="!mobileSize">
              {{ t("p-index-welcome-message-2") }}
            </p>
            <ClientOnly>
              <Transition>
                <div v-if="showRegButtons">
                  <NuxtLink :to="localePath('/login')">
                    <el-button round size="large" type="primary">
                      {{ t("p-index-get-started") }}
                    </el-button>
                  </NuxtLink>
                  <NuxtLink :to="localePath('/about')">
                    <el-button size="large" link>
                      {{ t("p-index-learn-more") }}
                    </el-button>
                  </NuxtLink>
                </div>
              </Transition>
            </ClientOnly>
            <div class="flex-grow mb-4"></div>
            <ul class="index-list">
              <li>
                <div class="color-primary">
                  <IconAuction />
                </div>
                <p class="par-size-XS">
                  {{ t("p-index-welcome-no-fees") }}
                </p>
              </li>
              <li>
                <div class="color-primary">
                  <IconShield />
                </div>
                <p class="par-size-XS">
                  {{ t("p-index-welcome-stable") }}
                </p>
              </li>
              <li>
                <div class="color-primary">
                  <IconAccount />
                </div>
                <p class="par-size-XS">
                  {{ t("p-index-welcome-no-id") }}
                </p>
              </li>
            </ul>
          </div>
        </el-carousel-item>
      </el-carousel>
    </ClientOnly>
  </div>
</template>

<style lang="scss" scoped>
.index-list {
  list-style: none;
  display: flex;
  gap: 16px;
  margin: 0 -4px;
  @media (max-width: 600px) {
    gap: 8px;
  }
  li {
    width: 33%;
  }
}
.walpaper-item {
  :deep(.walpaper-cont) {
    height: 100%;
  }
}
.carousel-cont {
  position: relative;

  .steps-cont {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    gap: 2px;
    justify-content: stretch;
    align-items: stretch;
    height: 15px;
    z-index: 1;
    box-sizing: border-box;
    padding: 0 8px;

    .step-item {
      height: 15px;
      padding-top: 8px;
      cursor: pointer;
      width: 100%;

      &::before {
        content: "";
        display: block;
        width: 100%;
        height: 2px;
        border-radius: 10px;
        transition: background-color 0.3s;
        background-color: rgba(#fff, 0.4);
      }
      &:hover::before,
      &.is-done::before {
        background-color: rgba(#fff, 0.9);
      }
      &.is-done::before {
        transition: none;
      }
      &.is-active::before {
        transition: none;
        background: linear-gradient(
          to right,
          rgba(#fff, 1) 49%,
          rgba(#fff, 0.4) 50%
        );
        background-size: 200% 100%;
        animation: fillGradient 8s linear forwards;
        animation-play-state: running;
      }
    }
  }
  &:hover {
    .step-item.is-active::before {
      animation-play-state: paused;
    }
  }
}

@keyframes fillGradient {
  from {
    background-position: 100% 0;
  }
  to {
    background-position: 0 0;
  }
}
.animated-gradient {
  width: 100%;
  height: 200px; /* Adjust height as needed */
  background: linear-gradient(to right, black 0%, black 50%, white 100%);
  background-size: 200% 100%;
  animation: fillGradient 8s linear forwards;
  animation-play-state: running; /* Ensure animation starts by default */
}

.animated-gradient:hover {
  animation-play-state: paused; /* Pause animation on hover */
}
</style>
