<script setup lang="ts">
import { fetchExchangeFrom, fetchExchangeTo } from "@/api";
import { Right, Bottom } from "@element-plus/icons-vue";
const { t } = useI18n();
const { width } = useWindowSize();
const mounted = useMounted();
const mobile = computed(() => width.value < 992 && mounted.value);

const router = useRouter();
const localePath = useLocalePath();
const goToExchange = () => {
  router.push({
    path: localePath("/exchange"),
    query: {
      cur_from: amountsForm.send?.partnerCode,
      cur_to: amountsForm.recieve?.partnerCode,
    },
  });
};
interface AmountsForm {
  send: string | any;
  recieve: string | any;
}

const amountsForm = reactive<AmountsForm>({
  send: "",
  recieve: "",
});

const selectSend = computed({
  get: () => amountsForm.send?.bpoId || amountsForm.send?.id,
  set: (value) => {
    const newVal = isNaN(+value)
      ? fromPairs.value?.crypto?.find((cur: any) => cur.id === value)
      : fromPairs.value?.bpo?.find((bpo: any) => bpo.bpoId === +value);
    amountsForm.send = newVal;
  },
});

const selectRecieve = computed({
  get: () => amountsForm.recieve?.bpoId || amountsForm.recieve?.id,
  set: (value) => {
    const newVal = isNaN(+value)
      ? toPairs.value?.crypto?.find((cur: any) => cur.id === value)
      : toPairs.value?.bpo?.find((bpo: any) => bpo.bpoId === +value);
    amountsForm.recieve = newVal;
  },
});
const loadingPairs = ref<boolean>(true);
const fromPairs = ref<any>({});
const toPairs = ref<any>({});

onBeforeMount(async () => {
  loadingPairs.value = true;
  fromPairs.value = await fetchExchangeFrom();
  let send = "";
  amountsForm.send =
    send || fromPairs.value?.crypto?.[0] || fromPairs.value?.bpo?.[0] || "";

  toPairs.value = await fetchExchangeTo(
    amountsForm.send.bpoId || amountsForm.send.id
  );
  let to = "";
  amountsForm.recieve =
    to || toPairs.value?.bpo?.[0] || toPairs.value?.crypto?.[0] || "";
  loadingPairs.value = false;
});

const loadingTo = ref<boolean>(false);

const sendWatcher = watch(
  () => amountsForm.send,
  async (_, oldVal) => {
    await nextTick();
    if (oldVal === "") {
      return;
    }
    loadingTo.value = true;
    toPairs.value = await fetchExchangeTo(
      amountsForm.send?.bpoId || amountsForm.send?.id
    );
    let found = false;
    let aliveItem: any = null;
    toPairs.value.bpo.forEach((item: any) => {
      aliveItem = aliveItem || item;
      if (item.bpoId === amountsForm.recieve?.bpoId) {
        found = true;
      }
    });
    toPairs.value.crypto.forEach((item: any) => {
      aliveItem = aliveItem || item;
      if (item?.id === amountsForm.recieve?.id) {
        found = true;
      }
    });
    if (!found) {
      amountsForm.recieve = aliveItem;
    }
    loadingTo.value = false;
  }
);

onBeforeUnmount(() => {
  sendWatcher();
});

const isOffline = computed(() => {
  return (
    !loadingPairs.value &&
    !fromPairs.value?.crypto?.length &&
    !fromPairs.value?.bpo?.length
  );
});
</script>

<template>
  <el-card class="widget-rel">
    <div class="search-exchange">
      <h2 class="title">
        {{ t("p-exchange-exchange") }}

        <span class="rotating"
          >{{
            amountsForm.send?.currency ||
            amountsForm.send?.name ||
            amountsForm.send
          }}
          {{
            amountsForm.send?.bpoId
              ? `(${amountsForm.send?.name})`
              : amountsForm.send?.id
              ? `(${amountsForm.send?.network})`
              : ""
          }}</span
        >
        {{ t("p-exchange-for") }}
        <span class="rotating delay"
          >{{
            amountsForm.recieve?.currency ||
            amountsForm.recieve?.name ||
            amountsForm.recieve
          }}
          {{
            amountsForm.recieve?.bpoId
              ? `(${amountsForm.recieve?.name})`
              : amountsForm.recieve?.id
              ? `(${amountsForm.recieve?.network})`
              : ""
          }}</span
        >
      </h2>
      <ClientOnly>
        <template #fallback>
          <el-skeleton :rows="1" animated />
        </template>
        <el-skeleton v-if="loadingPairs" :rows="1" animated />
        <el-row
          v-else
          :gutter="5"
          justify="space-between"
          align="middle"
          class="mb-8"
        >
          <el-col :span="24" :md="11" class="switch-cont">
            <el-select v-model="selectSend">
              <el-option-group :label="t('p-exchange-crypto')">
                <el-option
                  :label="`${option.name} (${option.network})`"
                  :value="option.id"
                  :key="option.id"
                  v-for="option in fromPairs.crypto"
                />
              </el-option-group>
              <el-option-group :label="t('p-exchange-fiat')">
                <el-option
                  :label="`${option.name} (${option.currency})`"
                  :value="option.bpoId"
                  :key="option.bpoId"
                  v-for="option in fromPairs.bpo"
                />
              </el-option-group>
            </el-select>
          </el-col>
          <el-col :span="24" :md="2" class="text-center">
            <el-icon class="right-icon"><Right /></el-icon>
            <el-icon class="bottom-icon"><Bottom /></el-icon>
          </el-col>
          <el-col :span="24" :md="11">
            <el-select v-model="selectRecieve" v-loading="loadingTo">
              <el-option-group :label="t('p-exchange-crypto')">
                <el-option
                  :label="`${option.name} (${option.network})`"
                  :value="option.id"
                  :key="option.id"
                  v-for="option in toPairs.crypto"
                />
              </el-option-group>
              <el-option-group :label="t('p-exchange-fiat')">
                <el-option
                  :label="`${option.name} (${option.currency})`"
                  :value="option.bpoId"
                  :key="option.bpoId"
                  v-for="option in toPairs.bpo"
                />
              </el-option-group>
            </el-select>
          </el-col>
        </el-row>
      </ClientOnly>

      <ExchangeCurrentRates
        :send="amountsForm.send"
        :recieve="amountsForm.recieve"
      />
      <div class="text-right">
        <el-button
          type="primary"
          size="large"
          round
          :class="{
            'w-full': mobile,
          }"
          @click="goToExchange"
        >
          {{ t("p-exchange-procees-to-search") }}
        </el-button>
      </div>
    </div>
    <Transition name="fade">
      <div v-if="isOffline" class="main-form-cont-overflow">
        <div class="center p-5">
          <h1>{{ t("p-exchange-offline-title") }}</h1>
          <p>
            {{ t("p-exchange-offline-par") }}
          </p>
        </div>
      </div>
    </Transition>
  </el-card>
</template>

<style scoped lang="scss">
.widget-rel {
  position: relative;

  .main-form-cont-overflow {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);

    .center {
      margin-top: 10px;
      background: var(--color-surface-primary);
      box-sizing: border-box;
      border: 1px solid var(--el-border-color);
      border-radius: 8px;
      box-shadow: 0 0 50px 50px var(--color-surface-primary);
    }
  }
}
.title {
  font-weight: 300;
}
.bottom-icon {
  display: none;
}
@media (max-width: 991px) {
  .right-icon {
    display: none;
  }
  .bottom-icon {
    display: inline-block;
  }
}
.switch-cont {
  position: relative;
  .switch-button,
  .eqiality {
    width: 32px;
    height: 32px;
    position: absolute;
    top: 0;
    left: 100%;
    margin: 0 8px;
    padding: 8px;
    box-sizing: border-box;
    line-height: 1;
  }
}
</style>
